var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "ejs-grid",
        {
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowSorting: true,
            toolbar: _vm.toolbarOptions,
            sortSettings: _vm.sortOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings,
            toolbarClick: _vm.toolbarClick,
            commandClick: _vm.commandClick,
            actionComplete: _vm.actionComplete
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: { field: "OfficeId", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "OfficeName",
                  headerText: _vm.$t("offices.office"),
                  width: "100",
                  visible: _vm.hasMultipleOffices
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "StartDateTime",
                  headerText: _vm.$t("shared.validFrom"),
                  width: "100",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EndDateTime",
                  headerText: _vm.$t("shared.validTo"),
                  width: "100",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: { width: "120", commands: _vm.commands }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }